import React from 'react'
import PropTypes from 'prop-types'

//import Image from './Image'
import BackgroundImage from 'gatsby-background-image'
import Content from './Content'
import './PageHeader.css'

const PageHeader = ({
  title,
  subtitle,
  backgroundImage,
  large,
  className = ''
}) => {
  
  return (
    <div >
      {backgroundImage && (
        <div className={`PageHeader relative ${className}`}>
        <BackgroundImage
          Tag="section"
          className={"BackgroundImage absolute"}
          fluid={backgroundImage.childImageSharp.fluid}
          backgroundColor={`#ffffff`}
        >
          
        </BackgroundImage>
        <div className="container relative">
          <h1 className="PageHeader--Title">{title}</h1>
          {subtitle && (
            <Content className="PageHeader--Subtitle" src={subtitle} />
          )}
        </div>
       </div>
      )}
      
    </div>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default PageHeader
