import React from 'react'
import { Link } from 'gatsby'

//import Image from './Image'
import BackgroundImage from 'gatsby-background-image'
import './PostCard.css'

const PostCard = ({
  featuredImage,
  title,
  excerpt,
  slug,
  categories = [],
  className = '',
  rating,
  ...props
}) => (
  <Link to={slug} className={`PostCard ${className}`}>
    {featuredImage && (
      <div className="PostCard--Image relative">
        <BackgroundImage
          Tag="section"
          className={"BackgroundImage absolute"}
          alt={title}
          fluid={featuredImage.childImageSharp.fluid}
          backgroundColor={`transparent`}
        ></BackgroundImage>
        <div className={"rating rating-"+rating}><div className={"rating--text"}>{rating}</div></div>
      </div>
    )}
    <div className="PostCard--Content relative">
    
      {title && <h3 className="PostCard--Title">{title}</h3>}
      <div className="PostCard--Category">
        {categories && categories.map(cat => cat.category).join(', ')}
      </div>
      {excerpt && <div className="PostCard--Excerpt">{excerpt}</div>}
    </div>
  </Link>
)

export default PostCard
